export const controllerAuthentication = ( step: number, data: any ) => {
    
    let formato;

    switch (step) {

        case 1:
            formato = {
                token: `Bearer ${data.token}`,
                email: data.user.email,
                id: data.user._id,
                token1: data.token
            }
            
            break;

        case 2:
            
            formato = {
                id: data.entities.entity._id,
                nombre: data.entities.entity.entity_name,
                descripcion: data.entities.entity.description
            }

            break;

        case 3:
            
            formato = {
                colorPrimario: data.entity.customize.colors.bg_buttons,
                colorSecundario: data.entity.customize.colors.bg_buttons,
                colorTerciario: data.entity.customize.colors.font_color,
                logo: data.entity.customize.logo,
                portada: data.entity.customize.portrait
            }
            document.documentElement.style.setProperty('--color-primary', formato.colorSecundario)
            break;
            
        case 4:
            formato = {
                id: data.user._id,
                rol: data.user.rol,
                dni: data.user.dni,
                nombres: data.user.name,
                apellidos: data.user.lastname,
                fecha_nacimiento: data.user.birthdate,
                lugar_nacimiento: data.user.place_of_birth,
                direccion: data.user.address,
                foto: data.user.photo,
                telefono: data.user.phone,
                email: data.user.email,
                curriculum_url: data.user.cv_url,
                linkedin_url: data.user.linkedin_url,
            }
            break;
    
        default:
            break;

    }

    return formato;

}