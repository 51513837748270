import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { ApiTicketsResponse, EntityParam, Ticket } from '../../app/shared/interfaces'
import { RootState } from '../store'

const { REACT_APP_STUDENT } = process.env

interface AddEditTicket extends EntityParam, Partial<Ticket> {
    fname: string
}

export const studentsApi = createApi({
    reducerPath: 'studentsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${REACT_APP_STUDENT}/v5.0.1/api`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token
            if (token) headers.set('authorization', `Bearer ${token}`)
            return headers
        },
    }),
    tagTypes: ['BillingInfo'],
    endpoints: (builder) => ({
        getBillingInfo: builder.query<{ ticket: Ticket }, EntityParam>({
            query: ({ entity }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getTicket',
                    entity,
                },
            }),
            providesTags: ['BillingInfo'],
            transformResponse: (response: ApiTicketsResponse) => ({
                ticket: {
                    ...response.ticket,
                    exist: response.document_exists,
                },
            }),
        }),
        addEditBillingInfo: builder.mutation<{ ticket: Ticket }, AddEditTicket>({
            query: ({ entity, ...ticket }) => ({
                url: '',
                method: 'POST',
                body: {
                    entity,
                    ...ticket,
                },
            }),
            invalidatesTags: ['BillingInfo'],
        }),
    }),
})

export const { useAddEditBillingInfoMutation, useGetBillingInfoQuery } = studentsApi
