import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

import {
    APIGetUsersByScheduled,
    EntityParam,
    Pagination,
    User,
} from '../../app/shared/interfaces'
import { RootState } from '../store'

const { REACT_APP_COURSES } = process.env

interface GetUsersByScheduledParams extends EntityParam {
    scheduled: string
    filter_by_name?: string
    page?: number
    docs?: number
}

export const coursesApi = createApi({
    reducerPath: 'coursesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${REACT_APP_COURSES}/v5.0.1/api`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token
            if (token) headers.set('authorization', `Bearer ${token}`)
            return headers
        },
    }),
    tagTypes: ['Students'],
    endpoints: (builder) => ({
        getStudentsByScheduled: builder.query<
            { users: User[]; pagination: Pagination },
            GetUsersByScheduledParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getUsersByScheduled',
                    rol: 'estudiante',
                    ...params,
                },
            }),
            transformResponse: (response: APIGetUsersByScheduled) => ({
                users: response.users.map((u) => ({
                    ...u.user,
                })),
                pagination: response.pagination,
            }),
            providesTags: (result) =>
                result?.users
                    ? [
                          ...result.users.map(({ _id }) => ({
                              type: 'Students' as const,
                              id: _id,
                          })),
                          { type: 'Students', id: 'LIST' },
                      ]
                    : [{ type: 'Students', id: 'LIST' }],
        }),
    }),
})

export const { useGetStudentsByScheduledQuery } = coursesApi
