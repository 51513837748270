import { Route, Redirect, RouteProps } from 'react-router-dom';

export interface IPrivateRouteProps extends RouteProps {
    isAuth: boolean // is authenticate route
}

export const PublicRoute = ({ isAuth, component: Component, ...rest }: IPrivateRouteProps) => {
    if (!Component) return null;
    return (
        <Route {...rest}
            render={(props: any) => (
                (!isAuth)
                ? (<Component {...props} />)
                : (<Redirect to="/dashboard" />)
            )}
        />
    )
}
