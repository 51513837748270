import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

import { APIValidateDniRucResponse } from '../../app/shared/interfaces'
import { RootState } from '../store'

const { REACT_APP_USERS } = process.env

interface ValidateDniRucParams {
    dni?: string
    ruc?: string
}

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${REACT_APP_USERS}/v5.0.1/api`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token
            if (token) headers.set('authorization', `Bearer ${token}`)
            return headers
        },
    }),
    tagTypes: ['Students'],
    endpoints: (builder) => ({
        validateDniRuc: builder.mutation<APIValidateDniRucResponse, ValidateDniRucParams>(
            {
                query: (params) => ({
                    url: '',
                    method: 'POST',
                    body: {
                        fname: 'getDNIorRUC',
                        ...params,
                    },
                }),
                // onQueryStarted: async (_, { queryFulfilled }) => {
                //     try {
                //         await queryFulfilled
                //         toast.success('Documento validado correctamente')
                //     } catch (error) {
                //         console.log(error)
                //         toast.error('Error al validar el documento')
                //     }
                // },
            },
        ),
    }),
})

export const { useValidateDniRucMutation } = usersApi
