import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import {
    APIGetCategoriesResponse,
    APIGetCourseModulesFreeResponse,
    APIGetSCheduledCoursesFreeResponse,
    APIGetScheduledsByCategoryFreeResponse,
    GetCourseModulesFreeParams,
    GetSCheduledCoursesFreeParams,
    GetScheduledsByCategoryFreeParams,
} from '../../app/shared/interfaces'

const { REACT_APP_STUDENT } = process.env

export const studentsPublicApi = createApi({
    reducerPath: 'studentsPublicApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${REACT_APP_STUDENT}/v5.0.1/`,
    }),
    endpoints: (builder) => ({
        getCategoriesFree: builder.query<APIGetCategoriesResponse, {}>({
            query: () => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getCategories',
                    domain: 'eco-emotions',
                },
            }),
        }),
        getScheduledsByCategoryFree: builder.query<
            APIGetScheduledsByCategoryFreeResponse,
            GetScheduledsByCategoryFreeParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getScheduledsByCategoryFree',
                    domain: 'eco-emotions',
                    ...params,
                },
            }),
        }),
        getScheduledCourseFree: builder.query<
            APIGetSCheduledCoursesFreeResponse,
            GetSCheduledCoursesFreeParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getScheduledCourseFree',
                    ...params,
                },
            }),
        }),
        getCourseModulesFree: builder.query<
            APIGetCourseModulesFreeResponse,
            GetCourseModulesFreeParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getCourseModulesFree',
                    ...params,
                },
            }),
        }),
    }),
})

export const {
    useGetCategoriesFreeQuery,
    useGetCourseModulesFreeQuery,
    useGetScheduledCourseFreeQuery,
    useGetScheduledsByCategoryFreeQuery,
} = studentsPublicApi
