import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'

import { AppThunk, RootState } from '../store'

import { axiosAuthentication, axiosUsersApi } from '../../app/config/axios'
import { controllerAuthentication } from '../../app/auth/service/AuthController'

export interface IUser {
    name: string
    lastname: string
    description: string
    dni: string
    genre: string
    place_of_birth: string
    birthdate: string
    phone: string
    cv_url: string
    address: string
    linkedin_url: string
    photo: string
    register: string
    rol: string
    documentType: string
    email_verification: boolean
    _id: string
    email: string
    code_verification: string
    __v: number
}

export interface Entity {
    _id: string
    success: boolean
    message: string
    rol: string
}

export interface ILoginResponse {
    message: string
    token: string
    user: IUser
    entity: Entity
    success: boolean
    is_valid: boolean
}

export interface IResponseGetUser {
    message: string
    user: IUser
    exist: boolean
}

interface IAuthState {
    user: IUser
    loadingAuth: boolean
    changePassword: boolean
    token: string
    editProfile: boolean
    year: string
}

const initialState: IAuthState = {
    user: {} as IUser,
    loadingAuth: false,
    changePassword: false,
    token: '',
    editProfile: false,
    year: '',
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, { payload }: PayloadAction<IUser>) => {
            state.user = payload
        },
        setToken: (state, { payload }: PayloadAction<string>) => {
            state.token = payload
        },
        setLoadingAuth: (state, { payload }: PayloadAction<boolean>) => {
            state.loadingAuth = payload
        },
        setChangePassword: (state, { payload }: PayloadAction<boolean>) => {
            state.changePassword = payload
        },
        setEditProfile: (state, { payload }: PayloadAction<boolean>) => {
            state.editProfile = payload
        },
        setYear: (state, { payload }: PayloadAction<string>) => {
            state.year = payload
        },
    },
})

export const {
    setUser,
    setToken,
    setLoadingAuth,
    setChangePassword,
    setEditProfile,
    setYear,
} = authSlice.actions

export const selectAuth = (state: RootState) => state.auth

// const token = localStorage.getItem('token')

export const getUserInfo = (): AppThunk => async (dispatch) => {
    await axiosUsersApi({
        url: '/v5.0.1/api',
        method: 'POST',
        data: {
            fname: 'getUser',
        },
        // headers: {
        //     Authorization: `Bearer ${token}`,
        // },
    })
        .then(({ data }: AxiosResponse<IResponseGetUser>) => {
            dispatch(setUser(data.user))
            dispatch(setYear(new Date().getFullYear().toString()))
        })
        .catch((e) => {
            console.log(e)
        })
}

export const resetPassword =
    (password: string): AppThunk =>
    async (dispatch) => {
        // dispatch(loadingAuth(true))
        // dispatch(setMainLoader(true))
        await axiosUsersApi({
            url: '/v5.0.1/api',
            method: 'POST',
            data: {
                fname: 'resetPassword',
                password,
            },
            // headers: {
            //     Authorization: `Bearer ${token}`,
            // },
        })
            .then(({ data, status }: AxiosResponse<ILoginResponse>) => {
                console.log(data.message)
                console.log(status)
                status === 200 && dispatch(setChangePassword(true))
                status === 200 && toast.success('Contraseña actualizada con éxito')
            })
            .catch((error) => {
                console.log(error)
                toast.error('Algo salió mal')
                // dispatch(loadingAuth(false))
                // dispatch(setMainLoader(false))
                dispatch(setChangePassword(false))
            })

        // dispatch(loadingAuth(false))
        // dispatch(setMainLoader(false))
    }

export interface IUpdateParams {
    data: FormData
}

export const updateUser =
    ({ data }: IUpdateParams): AppThunk =>
    async (dispatch, getState) => {
        const { token } = getState().auth
        // console.log('token', token)

        // dispatch(setMainLoader(true))
        dispatch(setLoadingAuth(true))
        await axiosAuthentication({
            url: '/v5.0.1/api',
            method: 'POST',
            data,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(({ data }: AxiosResponse<IResponseGetUser>) => {
                dispatch(setUser(data.user))
                dispatch(setEditProfile(false))

                const resp4 = controllerAuthentication(4, data)
                const user = localStorage.getItem('user')
                const pastUser = JSON.parse(user!)
                // console.log('pastuser', pastUser.usuario)
                // console.log('resp4', resp4)
                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        ...pastUser,
                        usuario: { ...pastUser.usuario, ...resp4 },
                    }),
                )
                toast.success(data.message)
                // dispatch(setMainLoader(false))
                dispatch(setLoadingAuth(false))
            })
            .catch((error) => {
                toast.error('No se pudo actualizar')
                console.log(error)
                // dispatch(setMainLoader(false))
                dispatch(setLoadingAuth(false))
            })
    }
