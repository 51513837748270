import { Redirect, Route, RouteProps } from "react-router-dom"

export interface IPrivateRouteProps extends RouteProps {
  isAuth: boolean // is authenticate route
}

export const PrivateRoute = ({ isAuth, component: Component, ...rest }: IPrivateRouteProps) => {

    if (!Component) return null;

    return (
        <Route {...rest}
            render={(props: any) => (
                (isAuth)
                ? (<Component {...props}/>)
                : (<Redirect to="/auth/login"/>)
            )}
        />
    )
}
