import axios from 'axios'

const {
    REACT_APP_STUDENT,
    REACT_APP_USERS,
    REACT_APP_ENTITIES,
    REACT_APP_EVALUATIONS,
    REACT_APP_MATERIALS,
    REACT_APP_COURSES,
    REACT_APP_CALENDAR,
} = process.env

export const getIdEntity = (localStg) => {
    try {
        const user = localStg.getItem('user')
        const {
            entidad: { id },
        } = JSON.parse(user)
        return id
    } catch (error) {
        return ''
    }
}

export const getStaticIdEntity = () => {
    return '60a2adea74a07200190effd2'
}

// const getUserToken = () => {
//     try {
//         const user = localStorage.getItem('user');
//         const { usuario: { token } } = JSON.parse(user);
//         return token;
//     } catch (error) {
//         return '';
//     }
// }

const axiosStudent = axios.create({
    baseURL: `${REACT_APP_STUDENT}/v5.0.1/`,
})

// axiosStudent.interceptors.request.use(
//     config => {
//         config.headers.Authorization = getUserToken();
//         return config;
//     },
//     // config => {
//     //   if (config.baseURL === baseApiAddress && !config.headers.Authorization) {
//     //     const token = getToken();
//     //     if (token) {
//     //       config.headers.Authorization = `Bearer ${token}`;
//     //     }
//     //   }
//     //   return config;
//     // },
//     error => Promise.reject(error)
// );

export const axiosAuthentication = axios.create({
    baseURL: `${REACT_APP_USERS}`,
})

export const axiosEntities = axios.create({
    baseURL: `${REACT_APP_ENTITIES}`,
})

export const axiosUsers = axios.create({
    baseURL: `${REACT_APP_USERS}`,
})

const axiosUsersApi = axios.create({
    baseURL: `${REACT_APP_USERS}`,
})

// axiosUsersApi.interceptors.request.use(
//     config => {
//         config.headers.Authorization = getUserToken();
//         return config;
//     },
//     error => Promise.reject(error)
// );

const axiosEvaluation = axios.create({
    baseURL: `${REACT_APP_EVALUATIONS}/v5.0.1/`,
})

const axiosCalendar = axios.create({
    baseURL: `${REACT_APP_CALENDAR}/v5.0.1/`,
})

// axiosCalendar.interceptors.request.use(
//     config => {
//         config.headers.Authorization = getUserToken();
//         return config;
//     },
//     error => Promise.reject(error)
// );

// axiosEvaluation.interceptors.request.use(
//     config => {
//         config.headers.Authorization = getUserToken();
//         return config;
//     },
//     error => Promise.reject(error)
// );

const axiosEntitiesApi = axios.create({
    baseURL: `${REACT_APP_ENTITIES}`,
})

// axiosEntitiesApi.interceptors.request.use(
//     config => {
//         config.headers.Authorization = getUserToken();
//         return config;
//     },
//     error => Promise.reject(error)
// );

// Temporal - Obsoleto
export const axiosBackOffice = axios.create({
    baseURL: '',
})

const axiosMaterials = axios.create({
    baseURL: `${REACT_APP_MATERIALS}/v5.0.1/`,
})

// axiosMaterials.interceptors.request.use(
//     config => {
//         config.headers.Authorization = getUserToken();
//         return config;
//     },
//     error => Promise.reject(error)
// );

const axiosCourses = axios.create({
    baseURL: `${REACT_APP_COURSES}/v5.0.1/`,
})

// axiosCourses.interceptors.request.use(
//     config => {
//         config.headers.Authorization = getUserToken();
//         return config;
//     },
//     error => Promise.reject(error)
// );

const axiosInstances = [
    axiosStudent,
    axiosUsersApi,
    axiosCalendar,
    axiosEvaluation,
    axiosEntitiesApi,
    axiosMaterials,
    axiosCourses,
]

// axiosCalendar.interceptors.request.use(
//     config => {
//         config.headers.Authorization = getUserToken();
//         return config;
//     },
//     error => Promise.reject(error)
// );

axiosInstances.forEach((axiosInstance) => {
    axiosInstance.interceptors.request.use((config) => {
        const token = localStorage.getItem('token')
        // const { usuario: { token } } = JSON.parse(user);

        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
            }
            // config.headers.Authorization = token
        } else {
            console.log('no hay token')
            window.location.href = '/auth/login'
        }
        return config
    })
    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const config = error.config

            if (error.response) {
                if (error.response.status === 401 && !config._retry) {
                    console.log('error axios config')
                    config._retry = true
                    // localStorage.setItem("token", await refreshAccessToken());
                    // localStorage.removeItem('token')
                    localStorage.clear()
                    localStorage.setItem('expired', 'true')
                    window.location.replace('/login')
                    // window.history.replaceState('/login', '')
                    return axios(config)
                }
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log('Error', error.message)
            }

            return Promise.reject(error)
        },
    )
})

export {
    axiosStudent,
    axiosUsersApi,
    axiosCalendar,
    axiosEvaluation,
    axiosEntitiesApi,
    axiosMaterials,
    axiosCourses,
}
