import { IStateAuth } from '../interfaces/IStateAuth';

interface IAction{
    type: String,
    payload: any
}

export const AuthReducer = (state: IStateAuth, action: IAction) : any  => {

    switch (action.type) {
            
        case 'AUTH_USER':
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true
            };
        case 'CLEAR_USER':
            return {
                ...state,
                isAuthenticated: false
            }
        case 'SET_CHECKING':
            return {
                ...state,
                checking: false
            }
        // case 'CREATE_<SERVICE>': 
        //     return {
        //         ...state,
        //         anything: [ ...state.anything, action.payload ]
        //     }

        // case 'DELETE_<SERVICE>':
        //     return {
        //         ...state,
        //         anything: state.anything.filter( (anything:any) => anything.id !== action.payload )
        //     }

        // case 'UPDATE_<SERVICE>':
        //     return {
        //         ...state,
        //         anything: state.anything.filter(( anything:any ) => {
        //             const company_updated = action.payload;
        //             if( anything.id === company_updated.id ) return company_updated;
        //             return anything;
        //         })
        //     }

        default:
            return state;
    }
    
};