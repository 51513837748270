import { createContext, useEffect, useReducer } from 'react';
import { AuthReducer } from './AuthReducer';
import { IStateAuth } from '../interfaces/IStateAuth';

const initialState: IStateAuth = {

    entidad: null,
    tema: {
        colorPrimario: '#FFFFFF',
        colorSecundario: '#FFFFFF'
    },
    isAuthenticated: false,
    checking: true

}

export const AuthContext = createContext<IStateAuth | any>(initialState);

export const AuthProvider = (props: any) => {

    const [state, dispatch]: any = useReducer<any>(AuthReducer, initialState);

    useEffect(() => {
        const user = localStorage.getItem('user')
        if (user) {
            const obj = JSON.parse(user)
            document.documentElement.style.setProperty('--color-primary', obj.estilo.colorSecundario)
        }
    }, [state])

    return <AuthContext.Provider value={{ state, dispatch }} >{props.children}</AuthContext.Provider>

}

