import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

import {
    APIGetMaterialsResponse,
    EntityParam,
    Material,
    Pagination,
    PaginationOpts,
} from '../../app/shared/interfaces'
import { RootState } from '../store'

const { REACT_APP_MATERIALS } = process.env

interface GetMaterialsParams extends Omit<PaginationOpts, 'query'> {
    scheduled_id: string
    query?: string
}

interface GetExtraMaterialsParams extends Omit<PaginationOpts, 'query'>, EntityParam {
    scheduled: string
    query?: string
}

export const materialsApi = createApi({
    reducerPath: 'materialsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${REACT_APP_MATERIALS}/v5.0.1/api`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token
            if (token) headers.set('authorization', `Bearer ${token}`)
            return headers
        },
    }),
    tagTypes: ['Materials', 'ExtraMaterials'],
    endpoints: (builder) => ({
        getMaterials: builder.query<
            { materials: Material[]; pagination: Pagination },
            GetMaterialsParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getMaterialsByModule',
                    ...params,
                },
            }),
            providesTags: ['Materials'],
            transformResponse: (response: APIGetMaterialsResponse) => ({
                materials: response.materials,
                pagination: response.pagination,
            }),
        }),
        getExtraMaterials: builder.query<
            { materials: Material[]; pagination: Pagination },
            GetExtraMaterialsParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getMaterialsModuleByStudent',
                    ...params,
                },
            }),
            providesTags: ['ExtraMaterials'],
            transformResponse: (response: APIGetMaterialsResponse) => ({
                materials: response.materials,
                pagination: response.pagination,
            }),
        }),
    }),
})

export const { useGetMaterialsQuery, useGetExtraMaterialsQuery } = materialsApi
