import * as React from "react";
import "./spinner.sass";
export interface ISpinner {
  type?: string;
  color?: string;
  pos?:string
}
const Spinner = (props: ISpinner) => {
  const { type ,pos} = props;
  
  return (
    <div className={`dashboard_spinner--box ${pos?'_--spinner-relative':''}`}>
      <div className="dashboard_spinner--wrap">
        {(function () {
          switch (type) {
            case "1":
              return <div className="dashboard_spinner--icon"></div>;
            case "2":
              return (
                <div className="sk-chase">
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                  <div className="sk-chase-dot"></div>
                </div>
              );
            case "3":
              return (
                <div className="spinner1">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
                </div>
              );
            case "4":
              return (
                <div className="spinner2">
                  <div className="rect1"></div>
                  <div className="rect2"></div>
                  <div className="rect3"></div>
                  <div className="rect4"></div>
                  <div className="rect5"></div>
                </div>
              );
            case "5":
              return (
                <div className="spinner3">
                  <div className="cube1"></div>
                  <div className="cube2"></div>
                </div>
              );
            case "6":
              return <div className="spinner4"></div>;
            case "7":
              return (
                <div className="spinner5">
                  <div className="dot1"></div>
                  <div className="dot2"></div>
                </div>
              );
            case "8":
              return (
                <div className="spinner6">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              );
            case "9":
              return (
                <div className="sk-circle">
                  <div className="sk-circle1 sk-child"></div>
                  <div className="sk-circle2 sk-child"></div>
                  <div className="sk-circle3 sk-child"></div>
                  <div className="sk-circle4 sk-child"></div>
                  <div className="sk-circle5 sk-child"></div>
                  <div className="sk-circle6 sk-child"></div>
                  <div className="sk-circle7 sk-child"></div>
                  <div className="sk-circle8 sk-child"></div>
                  <div className="sk-circle9 sk-child"></div>
                  <div className="sk-circle10 sk-child"></div>
                  <div className="sk-circle11 sk-child"></div>
                  <div className="sk-circle12 sk-child"></div>
                </div>
              );
            case "10":
              return (
                <div className="sk-cube-grid">
                  <div className="sk-cube sk-cube1"></div>
                  <div className="sk-cube sk-cube2"></div>
                  <div className="sk-cube sk-cube3"></div>
                  <div className="sk-cube sk-cube4"></div>
                  <div className="sk-cube sk-cube5"></div>
                  <div className="sk-cube sk-cube6"></div>
                  <div className="sk-cube sk-cube7"></div>
                  <div className="sk-cube sk-cube8"></div>
                  <div className="sk-cube sk-cube9"></div>
                </div>
              );
            case "11":
              return (
                <div className="sk-fading-circle">
                  <div className="sk-circle1 sk-circle"></div>
                  <div className="sk-circle2 sk-circle"></div>
                  <div className="sk-circle3 sk-circle"></div>
                  <div className="sk-circle4 sk-circle"></div>
                  <div className="sk-circle5 sk-circle"></div>
                  <div className="sk-circle6 sk-circle"></div>
                  <div className="sk-circle7 sk-circle"></div>
                  <div className="sk-circle8 sk-circle"></div>
                  <div className="sk-circle9 sk-circle"></div>
                  <div className="sk-circle10 sk-circle"></div>
                  <div className="sk-circle11 sk-circle"></div>
                  <div className="sk-circle12 sk-circle"></div>
                </div>
              );
            case "12":
              return (
                <div className="sk-folding-cube">
                  <div className="sk-cube1 sk-cube"></div>
                  <div className="sk-cube2 sk-cube"></div>
                  <div className="sk-cube4 sk-cube"></div>
                  <div className="sk-cube3 sk-cube"></div>
                </div>
              );
            case "13":
              return <div className="loader">Cargando...</div>;
            case "14":
              return <div className="lds-hourglass"></div>;
            case "15":
              return <div className="lds-dual-ring"></div>;
            default:
              return (
                <div className="sk-cube-grid">
                  <div className="sk-cube sk-cube1"></div>
                  <div className="sk-cube sk-cube2"></div>
                  <div className="sk-cube sk-cube3"></div>
                  <div className="sk-cube sk-cube4"></div>
                  <div className="sk-cube sk-cube5"></div>
                  <div className="sk-cube sk-cube6"></div>
                  <div className="sk-cube sk-cube7"></div>
                  <div className="sk-cube sk-cube8"></div>
                  <div className="sk-cube sk-cube9"></div>
                </div>
              );
          }
        })()}
      </div>
    </div>
  );
};

export default Spinner;


export const LazySpinner = () =>
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    height: '100vh'
  }}>
    <Spinner type="2"/>
  </div>