import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'

import { coursesApi, materialsApi, studentsApi, studentsPublicApi, usersApi } from './api'
import { authSlice } from './slices/authSlice'

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        [coursesApi.reducerPath]: coursesApi.reducer,
        [materialsApi.reducerPath]: materialsApi.reducer,
        [studentsApi.reducerPath]: studentsApi.reducer,
        [studentsPublicApi.reducerPath]: studentsPublicApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            coursesApi.middleware,
            materialsApi.middleware,
            studentsApi.middleware,
            studentsPublicApi.middleware,
            usersApi.middleware,
        ),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
